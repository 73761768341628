@font-face {
font-family: '__rijksFont_99240a';
src: url(/_next/static/media/3b47b83197930677-s.p.woff) format('woff');
font-display: swap;
font-weight: normal;
font-style: normal;
}

@font-face {
font-family: '__rijksFont_99240a';
src: url(/_next/static/media/0b06635008c26543-s.p.woff) format('woff');
font-display: swap;
font-weight: bold;
font-style: normal;
}

@font-face {
font-family: '__rijksFont_99240a';
src: url(/_next/static/media/9fa91490ac589088-s.p.woff) format('woff');
font-display: swap;
font-weight: normal;
font-style: italic;
}@font-face {font-family: '__rijksFont_Fallback_99240a';src: local("Arial");ascent-override: 97.57%;descent-override: 30.28%;line-gap-override: 0.00%;size-adjust: 92.14%
}.__className_99240a {font-family: '__rijksFont_99240a', '__rijksFont_Fallback_99240a', Tahoma, sans-serif
}

